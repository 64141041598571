* {
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    /* font-family: 'Aristotelica Small Caps', sans-serif; */
    font-family: 'Aristotelica Display Trial', sans-serif;
}

html {
    scroll-behavior: smooth;
    max-width: 1280px;
    margin: auto;
    background-color: lightgray;
}

body {
    max-width: 400px;
    margin: auto;
    background-color:white;
}

h2 {
    font-size: 2.3rem;
    font-weight: 500;
}

.vegetables {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    padding-bottom: 1rem;
}



