.about {
  background-color: ghostwhite;
  color: black;
  font-size: 16px;
  padding-bottom: 0.5rem;
}
.aboutImgs {
  width: 120px;
  height: 120px;
  /* height: ; */
  object-fit: cover;
  clip-path: circle();
}

.about p {
  font-weight: 100;
  line-height: 1.2rem;
  font-size: 1.2rem;
}

.about section {
  width: 90%;
  margin: auto;
}

.about a {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  margin: auto;
  width: 45%;
  background-color: #82add8;
  /* background-color: rgba(59, 91, 104, 0.233); */
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.219);
}

.menuWrapper {
  width: 100%;
  text-align: center;
}

.about article span {
  font-size: 4rem;
  padding: 0.5rem;
}

#miniCardsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}

.about h2, p {
  text-align: center;
}

.about a {
  text-decoration: none;
}