.navBar header {
  background-color: white;
  height: 80px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 12;
}
.navBar #navIcon {
  width: 40px;
}
.navBar h1 {
  width: calc(100% - 100px);
}

.navBar #siteLogoContainer {
  width: 60px;
  height: 60px;
}

.navBar #siteLogoContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: circle(49% at 50% 50%);
}

.navBar #sideNav {
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  /* opacity: 0; */
  transition: 0.5s;
  color: ghostwhite;
  overflow-x: hidden;
  padding-top: 4rem;
  z-index: 10;
}

.navBar #sideNav a {
  padding: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  font-size: 2rem;
  color: ghostwhite;
}

.navBar #sideNav a:hover {
  color: white;
  transition: 1s;
}

.navBar #closeBtn {
  position: absolute;
  background-color: transparent;
  border: none;
  color: grey;
  font-size: 16px;
  top: 0px;
  right: 25px;
}

