.scroll {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: tomato;
    color: white;
    font-size: 2rem;
    clip-path: circle();
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    opacity: 0.80;
    cursor: pointer;
    outline: 0 !important;
    
    z-index: 10;
}
