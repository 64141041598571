.spanContainer {
  text-align: center;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cardTitle {
  width: 100%;
  color: black;
  font-size: 1.2rem;
  padding: 0.35rem ;
  margin: 0;
  line-height: 1.2;
  box-sizing: border-box;
}

.cardsContainer {
 padding: 0.5rem;
}

.card #imgContainer {
  width: 100%;
  z-index: 1;
  padding: 0.25rem;
  box-sizing: border-box;

}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
