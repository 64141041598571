.footer {
  background-color: ghostwhite;
  color: grey;
  padding-top: 2rem;
  text-align: center;
}

.footer h2 {
  margin-bottom: 2rem;
  color: black;
}

.footer .contact {
  display: flex;
  justify-content: center;
}

.footer .contact a {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #82add8;
}

i {
  color: lightgray;
}

.footer p {
  font-family: 'Josefin Sans', sans-serif;
}

.footer #text {
  font-style: italic;
  color: black;

}

.footer a {
  display: block;
  text-decoration: none;
  color: grey;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.footer a:hover {
  color: goldenrod;
}

.footer #socialLinks {
  font-size: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}

.footer #links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  padding-bottom: 1rem;
  text-align: center;
}

.footer i {
  margin-right: 10px;
  color: #82add8;
}
